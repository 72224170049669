import styled, { css } from "styled-components"

const commonHeadingStyles = css`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  color: ${props => props.theme.colors.secondary};
`

export const H1 = styled.div`
  ${commonHeadingStyles};
  line-height: 43px;
  font-size: 36px;
`

export const H2 = styled.div`
  ${commonHeadingStyles};
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.03em;
`

export const H3 = styled.div`
  ${commonHeadingStyles};
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.03em;
`

export const H7 = styled.div`
  ${commonHeadingStyles};
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: ${props => props.theme.colors.secondary};
`

export const LargeText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: ${props => props.theme.colors.secondary};
`

import React, { MouseEvent } from "react"
import styled from "styled-components"
import { FaSearch } from "react-icons/fa"
import { Form, FormControl } from "react-bootstrap"
import { breakpoints } from "utils"

const Container = styled(Form)`
  width: 240px;
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
    margin-top: 20px;
  }
  position: relative;
  margin-bottom: 0;
  margin-left: auto;
`
const SearchIcon = styled(FaSearch)`
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 6px;
  height: 24px;
  width: 24px;
`
const Input = styled(FormControl)`
  font-size: 16px;
  padding-right: 32px;
`

interface Props {
  show?: boolean
  value?: string
  className?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit?: Function
}

const SearchInput: React.FC<Props> = props => {
  if (!props.show) {
    return null
  }
  return (
    <Container onSubmit={props.onSubmit} className={props.className}>
      <Input
        type="search"
        placeholder="Search here"
        value={props.value}
        onChange={props.onChange}
      />
      <SearchIcon
        onClick={(event: MouseEvent) => props.onSubmit && props.onSubmit()}
      />
    </Container>
  )
}

export default SearchInput

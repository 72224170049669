import _ from "lodash"
import fileDownload from "js-file-download"

import { Button } from "components/buttons"
import Loader from "components/Loader"
import { H1 } from "components/typography"
import { FaSearch } from "react-icons/fa"
import { IParticipant } from "models/IParticipant"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { FiDownload } from "react-icons/fi"
import UserTable from "./UserTable"
import PerPageSelector from "./PerPageSelector"
import Pager from "./Pager"
import { breakpoints } from "utils"
import SearchInput from "components/SearchInput"
import { IPagedResponse } from "models/IPagedResponse"
import { DeleteItemFunction } from "models/DeleteItemFunction"
import UserList from "./UserList"
import ConfirmModal from "components/ConfirmModal"
import { apiGetPatients } from "api/queries"

const Container = styled.div`
  margin-top: 38px;
  @media (max-width: ${breakpoints.desktop}px) {
    padding: 0 16px;
  }
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Footer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`
const DownloadButton = styled(Button)`
  margin-left: 36px;
`
const MobileButtonGroup = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`
const MobileSearchInput = styled(SearchInput)`
  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`
const SearchIcon = styled(FaSearch)`
  height: 28px;
  width: 28px;
  fill: ${props => props.theme.colors.primary};
  margin-bottom: 0;
`
const DownloadIcon = styled(FiDownload)`
  stroke: ${props => props.theme.colors.primary};
`

interface Props {}

const p = {
  items: [],
  total: 0,
  perPage: 10,
  page: 1,
}

const ParticipantsPage: React.FC<Props> = () => {
  const [pageInfo, setPageInfo] = useState<IPagedResponse<IParticipant>>(p)
  const [loading, setLoading] = useState<boolean>(false)
  const [searchIsVisible, setSearchIsVisible] = useState<boolean>(false)
  const [confirmModalIsVisible, setConfirmModalIsVisible] = useState<boolean>(
    false
  )
  const [searchInput, setSearchInput] = useState<string>("")
  const [deleteItemId, setDeleteItemId] = useState<string>()

  const loadTableData = async (
    page: number,
    perPage: number,
    append = false
  ) => {
    try {
      const response = await api.fetchParticipants(page, perPage)
      if (append) {
        const newItems = [...pageInfo.items, ...response.items]
        setPageInfo(
          _.assign({}, response, {
            items: newItems,
          })
        )
      } else {
        setPageInfo(response)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const toggleSearchInput = () => {
    setSearchIsVisible(!searchIsVisible)
  }
  const onDownload = async () => {
    // const response = await api.downloadParticipantCsv()
    const response = await apiGetPatients()
    fileDownload(response, "participants.csv")
  }
  const onSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchInput(value)
    const response = await api.fetchParticipants(p.page, p.perPage, value)
    setPageInfo(response)
  }
  const onDeleteItem: DeleteItemFunction = async (id: string) => {
    setDeleteItemId(id)
    setConfirmModalIsVisible(true)
  }
  const onDeleteItemConfirm: DeleteItemFunction = async () => {
    if (deleteItemId) {
      await api.deleteParticipant(deleteItemId)
      await loadTableData(pageInfo.page, pageInfo.perPage)
    }
    setConfirmModalIsVisible(false)
  }
  const onPerPageChange = (perPage: number) => {
    setPageInfo(Object.assign({}, pageInfo, { perPage }))
    loadTableData(p.page, perPage)
  }

  useEffect(() => {
    // loadTableData(pageInfo.page, pageInfo.perPage)
  }, [])

  // if (loading) {
  //   return (
  //     <Container>
  //       <Loader />
  //     </Container>
  //   )
  // }

  return (
    <Container>
      <Header>
        <H1>Participants</H1>
        <ButtonGroup>
          {/* {!searchIsVisible && (
            <SearchIcon
              id="participants-search-icon"
              onClick={toggleSearchInput}
            />
          )}
          <SearchInput
            value={searchInput}
            onChange={onSearch}
            show={searchIsVisible}
          /> */}
          <DownloadButton outline onClick={onDownload}>
            Download CSV
          </DownloadButton>
        </ButtonGroup>
        <MobileButtonGroup>
          <DownloadIcon onClick={onDownload} />
        </MobileButtonGroup>
      </Header>
      {/* <MobileSearchInput value={searchInput} onChange={onSearch} show />
      <UserTable items={pageInfo.items} onDeleteItem={onDeleteItem} />
      <UserList
        items={pageInfo.items}
        onDeleteItem={onDeleteItem}
        loadMore={() =>
          loadTableData(pageInfo.page + 1, pageInfo.perPage, true)
        }
      />
      <Footer>
        <PerPageSelector
          onChange={onPerPageChange}
          perPage={pageInfo.perPage}
          total={pageInfo.total}
        />
        <Pager
          page={pageInfo.page}
          totalPages={
            pageInfo.perPage ? Math.ceil(pageInfo.total / pageInfo.perPage) : 0
          }
          onPageClick={selectedItem =>
            loadTableData(selectedItem.selected + 1, pageInfo.perPage)
          }
        />
      </Footer> */}
      {/* <ConfirmModal
        title="Delete Participant"
        show={confirmModalIsVisible}
        onClose={() => setConfirmModalIsVisible(false)}
        onConfirm={onDeleteItemConfirm}
      >
        Are you sure you want to delete this participant record?
      </ConfirmModal> */}
    </Container>
  )
}

export default ParticipantsPage

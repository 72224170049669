import Layout from "components/layout"
import SEO from "components/seo"
import React from "react"
import ParticipantsPage from "components/participantsPage"

interface Props {}

const Participants: React.FC<Props> = () => {
  return (
    <Layout>
      <SEO title="Participants" />
      <ParticipantsPage />
    </Layout>
  )
}

export default Participants
